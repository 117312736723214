import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/terms-of-use/Hero";
import MainContent from "../components/terms-of-use/MainContent";
import { useTouQuery } from "../hooks/useTouQuery";
import Helmet from "react-helmet";

const TermsOfUsePage = () => {
    const data = useTouQuery();
    return (
        <Layout>
            <Hero data={data.touHeader} />
            <MainContent data={data.touContent} />
            <Helmet>
                <title>{data.touMetaData.metaTitle}</title>
                <meta name="description" content={data.touMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default TermsOfUsePage;